exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-en-blog-index-tsx": () => import("./../../../src/pages/en/blog/index.tsx" /* webpackChunkName: "component---src-pages-en-blog-index-tsx" */),
  "component---src-pages-en-delete-account-index-tsx": () => import("./../../../src/pages/en/delete-account/index.tsx" /* webpackChunkName: "component---src-pages-en-delete-account-index-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-privacy-policy-tsx": () => import("./../../../src/pages/en/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-en-privacy-policy-tsx" */),
  "component---src-pages-en-terms-of-service-tsx": () => import("./../../../src/pages/en/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-en-terms-of-service-tsx" */),
  "component---src-pages-es-blog-index-tsx": () => import("./../../../src/pages/es/blog/index.tsx" /* webpackChunkName: "component---src-pages-es-blog-index-tsx" */),
  "component---src-pages-es-delete-account-index-tsx": () => import("./../../../src/pages/es/delete-account/index.tsx" /* webpackChunkName: "component---src-pages-es-delete-account-index-tsx" */),
  "component---src-pages-es-index-tsx": () => import("./../../../src/pages/es/index.tsx" /* webpackChunkName: "component---src-pages-es-index-tsx" */),
  "component---src-pages-es-privacy-policy-tsx": () => import("./../../../src/pages/es/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-es-privacy-policy-tsx" */),
  "component---src-pages-es-terms-of-service-tsx": () => import("./../../../src/pages/es/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-es-terms-of-service-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

