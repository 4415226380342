import { Theme, createTheme } from "@mui/material/styles";

const theme: Theme = createTheme({
    palette: {
        primary: { main: '#666C49' },
        secondary: { main: '#CFC5C1' },
        background: { default: '#FFFFFF' },
        dark: { main: '#415236' },
        base: { main: '#666C49' },
        light: { main: '#808061' },
        lighter: { main: '#A89F91' },
        lightest: { main: '#CFC5C1' },
        white: {main: '#FFFFFF'},
        black: {main: '#000000'}
    },
    typography: {
        fontFamily: 'InriaSerif-Regular',
        allVariants: {
            fontFamily: 'InriaSerif-Regular'
        }
    }
});

export default theme