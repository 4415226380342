import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { APP, AppStateType } from './appTypes';
import { LanguageEnum } from '../../types/language';

const initialState: AppStateType = {
    isConnected: true,
    language: LanguageEnum.en,
}

export const appSlice = createSlice({
    name: APP,
    initialState,
    reducers: {
        changeLanguage: (state: AppStateType, action: PayloadAction<LanguageEnum>) => {
            state.language = action.payload;
        },
    },
})

export const {
    changeLanguage
} = appSlice.actions

export default appSlice.reducer