import { LanguageEnum } from "../../types/language";

export interface AppStateType {
    isConnected: boolean;
    language: LanguageEnum;
}

export const APP = "app";
export type APP = typeof APP;

export const CHANGE_LANGUAGE = `${APP}/changeLanguage`;
export type CHANGE_LANGUAGE = typeof CHANGE_LANGUAGE;