import appReducer from "../slices/app/appSlice"
import { AppStateType } from "../slices/app/appTypes";
import { combineReducers } from "@reduxjs/toolkit";

export type StateType = {
    app: AppStateType;
};

const rootReducers = combineReducers({
    app: appReducer,
});

export default rootReducers;