import React from "react"
import { ThemeProvider } from "@mui/material"
import theme from "./src/theme/theme"
import "./src/styles/global.css"
import { Provider } from "react-redux"
import { store } from "./src/redux/store"
import "./src/localization/i18n"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
    // console.log("new pathname", location.pathname)
    // console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}

/* export const wrapPageElement = ({ element, props }) => {
    return <ThemeProvider theme={theme}>{element}</ThemeProvider>
} */

export const wrapRootElement = ({ element, props }) => {
    return (
        <Provider store={store} >
            <ThemeProvider theme={theme}>
                {element}
            </ThemeProvider>
        </Provider>
    )
}